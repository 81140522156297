<template>
  <div class="question questionPublic">
    <img src="@assets/images/questionPub.jpg" />
    <div class="container">
      <form>
        <div class="tips">各位学员：</div>
        <p>
          感谢您参与本次密训，耽误您几分钟时间请您协助完成此份调查问卷，您的评价对于我们改进培训工作来说非常重要。请在相对应的括号内打“√”。谢谢您的配合！
        </p>
        <!-- 投诉电话 -->
        <div style="margin-bottom: 10px">
          <a>投诉电话：</a>
          <a href="tel:400 696 8687" class="call" style="color: blue"
            >400 696 8687</a
          >
        </div>
       <!-- 课程 -->
        <div class="box">
          <span>课程名称：</span>
          <van-dropdown-menu title="请选择课程名称">
            <van-dropdown-item
              v-model="lesson_id"
              @change="change"
              :options="lessonData"
            />
          </van-dropdown-menu>
        </div>
        <!-- 主讲老师  -->
        <!-- <div class="box">
          <span>主讲老师：</span>
          <input
            type="text"
            maxlength="20"
            v-model="teacher"
            placeholder="请输入主讲老师名称"
          />
        </div> -->
        <!-- 站点 -->
        <div class="box">
          <span>站点：</span>
          <van-dropdown-menu title="请选择站点">
            <van-dropdown-item
              v-model="site_id"
              :disabled="disabled"
              :options="siteList"
            />
          </van-dropdown-menu>
        </div>
        <div class="box">
          <span>主办方：</span>
          <van-dropdown-menu title="请选择主办方">
            <van-dropdown-item v-model="company_id" :options="companyList" />
          </van-dropdown-menu>
        </div>

        <div class="title">一丶培训组织方面</div>
        <div class="radio-title">1、在参加公开课前您得到的通知是否及时？</div>
        <van-radio-group v-model="form.a1">
          <van-radio icon-size=".32rem" name="很好" label-position="left"
            >很好</van-radio
          >
          <van-radio icon-size=".32rem" name="好" label-position="left"
            >好</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不好" label-position="left"
            >不好</van-radio
          >
          <van-radio icon-size=".32rem" name="差" label-position="left"
            >差</van-radio
          >
        </van-radio-group>

        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.a1"
          />
        </div>

        <div class="radio-title">2、客服人员的服务水平和主动性是否满意？</div>
        <van-radio-group v-model="form.a2">
          <van-radio icon-size=".32rem" name="很主动" label-position="left"
            >很主动</van-radio
          >
          <van-radio icon-size=".32rem" name="比较主动" label-position="left"
            >比较主动</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不太主动" label-position="left"
            >不太主动</van-radio
          >
          <van-radio icon-size=".32rem" name="很差" label-position="left"
            >很差</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.a5" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.a2"
          />
        </div>

        <div class="radio-title">3、工作人员是否主动提醒您签到和退定位费？</div>
        <van-radio-group v-model="form.a3">
          <van-radio icon-size=".32rem" name="很主动" label-position="left"
            >很主动</van-radio
          >
          <van-radio icon-size=".32rem" name="比较主动" label-position="left"
            >比较主动</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不太主动" label-position="left"
            >不太主动</van-radio
          >
          <van-radio icon-size=".32rem" name="很差" label-position="left"
            >很差</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.a4" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.a3"
          />
        </div>

        <div class="radio-title">4、主持人在调节课堂气氛方面做得如何？</div>
        <van-radio-group v-model="form.a4">
          <van-radio icon-size=".32rem" name="非常好" label-position="left"
            >非常好</van-radio
          >
          <van-radio icon-size=".32rem" name="还好" label-position="left"
            >还好</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不好" label-position="left"
            >不好</van-radio
          >
          <van-radio icon-size=".32rem" name="差" label-position="left"
            >差</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.a6" placeholder="原因说明..." /> -->
        </van-radio-group>

        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.a4"
          />
        </div>

        <div class="radio-title">5、您觉得现场影音设备效果是否满意？</div>
        <van-radio-group v-model="form.a5">
          <van-radio icon-size=".32rem" name="很好" label-position="left"
            >很好</van-radio
          >
          <van-radio icon-size=".32rem" name="还行" label-position="left"
            >还行</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不好" label-position="left"
            >不好</van-radio
          >
          <van-radio icon-size=".32rem" name="听不见" label-position="left"
            >听不见</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.a7" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.a5"
          />
        </div>

        <div class="radio-title">6、助教老师的专业能力您是否满意？</div>
        <van-radio-group v-model="form.a6">
          <van-radio icon-size=".32rem" name="非常满意" label-position="left"
            >非常满意</van-radio
          >
          <van-radio icon-size=".32rem" name="比较满意" label-position="left"
            >比较满意</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不太满意" label-position="left"
            >不太满意</van-radio
          >
          <van-radio icon-size=".32rem" name="不满意" label-position="left"
            >不满意</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.a7" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.a6"
          />
        </div>

        <div class="radio-title">7、助教老师和您的沟通是否让感到舒适？</div>
        <van-radio-group v-model="form.a7">
          <van-radio icon-size=".32rem" name="非常舒适" label-position="left"
            >非常舒适</van-radio
          >
          <van-radio icon-size=".32rem" name="比较舒适" label-position="left"
            >比较舒适</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不太舒适" label-position="left"
            >不太舒适</van-radio
          >
          <van-radio icon-size=".32rem" name="很不满" label-position="left"
            >很不满</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.a7" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.a7"
          />
        </div>

        <div class="radio-title">
          8、您对本次公开课课程规矩和课堂纪律印象如何？
        </div>
        <van-radio-group v-model="form.a8">
          <van-radio icon-size=".32rem" name="很好" label-position="left"
            >很好</van-radio
          >
          <van-radio icon-size=".32rem" name="好" label-position="left"
            >好</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不好" label-position="left"
            >不好</van-radio
          >
          <van-radio icon-size=".32rem" name="差" label-position="left"
            >差</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.a7" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.a8"
          />
        </div>

        <div class="title">二、课程安排</div>
        <div class="radio-title">1、课程内容对您是否有帮助？</div>
        <van-radio-group v-model="form.b1">
          <van-radio icon-size=".32rem" name="很有帮助" label-position="left"
            >很有帮助</van-radio
          >
          <van-radio icon-size=".32rem" name="较有帮助" label-position="left"
            >较有帮助</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="没用" label-position="left"
            >没用</van-radio
          >
          <van-radio icon-size=".32rem" name="差" label-position="left"
            >差</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b2" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.b1"
          />
        </div>

        <div class="radio-title">2、您认为课程流程是否适宜？</div>
        <van-radio-group v-model="form.b2">
          <van-radio icon-size=".32rem" name="非常清晰" label-position="left"
            >非常清晰</van-radio
          >
          <van-radio icon-size=".32rem" name="比较合适" label-position="left"
            >比较合适</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="有点混乱" label-position="left"
            >有点混乱</van-radio
          >
          <van-radio icon-size=".32rem" name="完全混乱" label-position="left"
            >完全混乱</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b2" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.b2"
          />
        </div>

        <div class="title">三、讲师评价</div>
        <div class="radio-title">1、讲师的授课方式您是否满意？</div>
        <van-radio-group v-model="form.c1">
          <van-radio icon-size=".32rem" name="非常满意" label-position="left"
            >非常满意</van-radio
          >
          <van-radio icon-size=".32rem" name="比较满意" label-position="left"
            >比较满意</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不太满意" label-position="left"
            >不太满意</van-radio
          >
          <van-radio icon-size=".32rem" name="不满意" label-position="left"
            >不满意</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b4" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.c1"
          />
        </div>

        <div class="radio-title">2、讲师的实操讲解您是否满意？</div>
        <van-radio-group v-model="form.c2">
          <van-radio icon-size=".32rem" name="很详细" label-position="left"
            >很详细</van-radio
          >
          <van-radio icon-size=".32rem" name="比较详细" label-position="left"
            >比较详细</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不太详细" label-position="left"
            >不太详细</van-radio
          >
          <van-radio icon-size=".32rem" name="专业不行" label-position="left"
            >专业不行</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b2" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.c2"
          />
        </div>

        <div class="radio-title">3、讲师的授课速度您是否可以接受？</div>
        <van-radio-group v-model="form.c3">
          <van-radio icon-size=".32rem" name="很好" label-position="left"
            >很好</van-radio
          >
          <van-radio icon-size=".32rem" name="好" label-position="left"
            >好</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不好" label-position="left"
            >不好</van-radio
          >
          <van-radio icon-size=".32rem" name="差" label-position="left"
            >差</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b1" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.c3"
          />
        </div>

        <div class="radio-title">4、讲师在调节课堂氛围方面您是否满意？</div>
        <van-radio-group v-model="form.c4">
          <van-radio icon-size=".32rem" name="很好" label-position="left"
            >很好</van-radio
          >
          <van-radio icon-size=".32rem" name="好" label-position="left"
            >好</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不好" label-position="left"
            >不好</van-radio
          >
          <van-radio icon-size=".32rem" name="差" label-position="left"
            >差</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b1" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.c4"
          />
        </div>

        <div class="radio-title">
          5、讲师回答学员问题的态度和专业度您是否满意？
        </div>
        <van-radio-group v-model="form.c5">
          <van-radio icon-size=".32rem" name="非常满意" label-position="left"
            >非常满意</van-radio
          >
          <van-radio icon-size=".32rem" name="比较满意" label-position="left"
            >比较满意</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不太满意" label-position="left"
            >不太满意</van-radio
          >
          <van-radio icon-size=".32rem" name="不满意" label-position="left"
            >不满意</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b4" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.c5"
          />
        </div>

        <div class="title">四、产品方面</div>
        <div class="radio-title">1、产品数量和使用感您是否满意？</div>
        <van-radio-group v-model="form.d1">
          <van-radio icon-size=".32rem" name="很好" label-position="left"
            >很好</van-radio
          >
          <van-radio icon-size=".32rem" name="好" label-position="left"
            >好</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不好" label-position="left"
            >不好</van-radio
          >
          <van-radio icon-size=".32rem" name="差" label-position="left"
            >差</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b1" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.d1"
          />
        </div>

        <div class="radio-title">2、产品价格您觉得是否合适？</div>
        <van-radio-group v-model="form.d2">
          <van-radio icon-size=".32rem" name="很好" label-position="left"
            >很好</van-radio
          >
          <van-radio icon-size=".32rem" name="好" label-position="left"
            >好</van-radio
          >
          <van-radio icon-size=".32rem" name="一般" label-position="left"
            >一般</van-radio
          >
          <van-radio icon-size=".32rem" name="不好" label-position="left"
            >不好</van-radio
          >
          <van-radio icon-size=".32rem" name="差" label-position="left"
            >差</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b1" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>原因：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.d2"
          />
        </div>

        <div class="title">五、您对本次公开课课程的评价</div>
        <div class="radio-title">
          1、你认为通过公开课，会给您自身的发展带来何种作用？
        </div>
        <van-radio-group v-model="form.e1">
          <van-radio icon-size=".32rem" name="增加知识" label-position="left"
            >增加知识</van-radio
          >
          <van-radio icon-size=".32rem" name="提高技能" label-position="left"
            >提高技能</van-radio
          >
          <van-radio icon-size=".32rem" name="开阔视野" label-position="left"
            >开阔视野</van-radio
          >
          <van-radio icon-size=".32rem" name="规范行为" label-position="left"
            >规范行为</van-radio
          >
          <van-radio icon-size=".32rem" name="增进沟通" label-position="left"
            >增进沟通</van-radio
          >
          <van-radio
            icon-size=".32rem"
            name="增加企业归属感"
            label-position="left"
            >增加企业归属感</van-radio
          >
          <van-radio
            icon-size=".32rem"
            name="明确发展目标及方向"
            label-position="left"
            >明确发展目标及方向</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b1" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>其他：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.e1"
          />
        </div>

        <div class="radio-title">
          2、你认为目前开展的各类公开课哪些地方有待改进？
        </div>
        <van-radio-group v-model="form.e2">
          <van-radio
            icon-size=".32rem"
            name="培训内容理论度应深化"
            label-position="left"
            >培训内容理论度应深化</van-radio
          >
          <van-radio
            icon-size=".32rem"
            name="培训内容讲解延长，宣传时间应减短"
            label-position="left"
            >培训内容讲解延长，宣传时间应减短</van-radio
          >
          <van-radio
            icon-size=".32rem"
            name="实操的时间应延长"
            label-position="left"
            >实操的时间应延长</van-radio
          >
          <van-radio
            icon-size=".32rem"
            name="实操演示次数和模特选取应增加的时间应延长"
            label-position="left"
            >实操演示次数和模特选取应增加的时间应延长</van-radio
          >
          <van-radio
            icon-size=".32rem"
            name="小组助教老师人员不够，可适当增加"
            label-position="left"
            >小组助教老师人员不够，可适当增加</van-radio
          >
          <!-- <input type="text" maxlength="50" v-model="cause.b1" placeholder="原因说明..." /> -->
        </van-radio-group>
        <div class="reason">
          <span>其他：</span>
          <input
            class="reason-input"
            type="text"
            maxlength="50"
            v-model="cause.e2"
          />
        </div>

        <div class="title">六、您对公司的课程和管理有什么建议和意见？</div>
        <textarea v-model="opinion"></textarea>

        <div class="submit" @click="submitQuestion()">提交问卷</div>
        <h4>
          再次感谢您的配合！
          <br />期待与您再次相会在聚亿美大家庭！
        </h4>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { RadioGroup, Radio, DropdownMenu, DropdownItem } from "vant";
import { questionLessonPublic, questionPublic } from "@api/user";
// 引入防抖和节流函数
import globalFunction from "@utils/globalFunction";
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
export default {
  data() {
    return {
      disabled: true,
      id: "",
      lesson_id: "",
      site_id: "", //站点id
      company_id: "",
      allList: [],
      lessonData: [], //课程
      siteList: [], //站点列表
      companyList: [], //公司列表
      form: {
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        a5: "",
        a6: "",
        a7: "",
        a8: "",
        b1: "",
        b2: "",
        c1: "",
        c2: "",
        c3: "",
        c4: "",
        c5: "",
        d1: "",
        d2: "",
        e1: "",
        e2: "",
      },
      cause: {
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        a5: "",
        a6: "",
        a7: "",
        a8: "",
        b1: "",
        b2: "",
        c1: "",
        c2: "",
        c3: "",
        c4: "",
        c5: "",
        d1: "",
        d2: "",
        e1: "",
        e2: "",
      },
      opinion: "",
    };
  },
  created() {
    this.id = this.$route.query.id;
    // this.id = 328;
    this.getLesson();
  },
  methods: {
    // 获取课程
    getLesson() {
      questionLessonPublic(1).then((res) => {
        // console.log(res.data.data[328].site_list)
        this.allList = res.data.data;
        const data = res.data.data;
        const companyList = res.data.companyList;
        // debugger
        const array = [];
        const companyArr = [];
        const siteArr = [];

        // 课程
        for (const key in data) {
          if (key == this.id) {
            array.unshift({
              value: key,
              text: data[key].bookname,
            });
          } else {
            array.push({
              value: key,
              text: data[key].bookname,
            });
          }
        }
        let boo = array.some((item) => item.value == this.id);
        if (!boo) array.unshift({ value: 0, text: "请选择课程" });
        this.lessonData = array;
        this.lesson_id = array[0].value;
        // console.log(this.lessonData);

        // 培训站点
        // console.log(this.lesson_id);
        if (this.lesson_id == 0) {
          this.disabled = true;
          siteArr.unshift({ value: 0, text: "请选择站点" });
          this.siteList = siteArr;
          this.site_id = siteArr[0].value;
        }
        if (this.lesson_id !== 0) {
          this.disabled = false;
          const siteData = this.allList[this.lesson_id].site_list;
          for (const sitekey in siteData) {
            siteArr.push({
              value: sitekey,
              text: siteData[sitekey].alias,
            });
          }

          siteArr.unshift({ value: 0, text: "请选择站点" });
          this.siteList = siteArr;
          this.site_id = siteArr[0].value;
          // console.log(this.siteList)
        }

        // 主办公司
        for (const key2 in companyList) {
          companyArr.push({
            value: key2,
            text: companyList[key2],
          });
        }
        companyArr.unshift({ value: 0, text: "请选择主办方" });
        this.companyList = companyArr;
        this.company_id = companyArr[0].value;
      });
    },
    // 选择课程  后  获取课程的站点
    change() {
      const siteArr = [];
      if (this.lesson_id == 0) {
        this.disabled = true;
        siteArr.unshift({ value: 0, text: "请选择站点" });
        this.siteList = siteArr;
        this.site_id = siteArr[0].value;
        return;
      }

      this.disabled = false;
      const siteData = this.allList[this.lesson_id].site_list;
      for (const key in siteData) {
        siteArr.push({
          value: key,
          text: siteData[key].alias,
        });
      }
      siteArr.unshift({ value: 0, text: "请选择站点" });
      this.siteList = siteArr;
      this.site_id = siteArr[0].value;
      // console.log(this.siteList)
    },

    // 请求提交接口
    sendAjax() {
      if (this.lesson_id == 0) return;
      // if (this.company_id == 0) return;
      let params = {};
      let form = this.form;
      // 必填提示
      if (!this.lesson_id) {
        this.$dialog.error("课程名称必填");
        return;
      }
      if (!this.site_id) {
        this.$dialog.error("站点必填");
        return;
      }

      if (!this.company_id) {
        this.$dialog.error("主办方必填");
        return;
      }

      for (const key in form) {
        if (!form[key]) {
          this.$dialog.error("请填写选项一、选项二、选项三、选项四、选项五");
          return;
        }
      }
      // this.$dialog.loading.open("正在提交中...");
      params = {
        lesson_id: this.lesson_id,
        company_id: this.company_id,
        site_id: this.site_id,
        opinion: this.opinion,
        cause: JSON.stringify(this.cause),
        ...this.form,
      };

      questionPublic(params)
        .then((res) => {
          //  this.$dialog.loading.close();
          this.$dialog.success(res.msg);
          setTimeout(() => {
            this.$router.push('/user')
          }, 1500);
        })
        .catch((err) => {
          this.$dialog.error(err.msg);
        });
    },
    // 提交问卷
    submitQuestion:globalFunction.debounce(function(){
      this.sendAjax()
    },2000),
  },
};
</script>

<style scoped lang="scss">
.question {
  padding: 0.2rem;
  background: linear-gradient(#fff, #354af5);
  color: black;
  img {
    width: 100%;
    height: auto;
  }
  .container {
    background: white;
    font-size: 0.24rem;
    padding: 0.2rem;
    .box {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      height: 0.6rem;
      line-height: 0.6rem;
      span {
        width: 20%;
      }
      .van-dropdown-menu {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      input {
        width: 78%;
        text-align: right;
        border-bottom: 1px solid #000;
      }
    }
    .tips {
      color: #354af5;
      border-bottom: 1px dashed #354af5;
      font-size: 0.32rem;
      line-height: 0.72rem;
    }
    p {
      text-indent: 2em;
      line-height: 0.4rem;
      margin: 0.2rem 0 0.3rem 0;
    }
    .van-radio-group {
      display: flex;
      flex-flow: row wrap;
      .van-radio {
        margin-bottom: 0.2rem;
        margin-right: 14px;
        &:last-child {
          margin-right: initial;
        }
      }
      input[type="text"] {
        margin-left: 0.2rem;
        width: 1.6rem;
        border-bottom: 1px solid #666;
      }
    }
    .reason {
      display: flex;
      &-input {
        flex: 1;
        border-bottom: 1px solid #ccc;
      }
    }
    .title {
      font-weight: 700;
      font-size: 0.26rem;
      height: 0.5rem;
      line-height: 0.5rem;
      padding-left: 0.2rem;
      background: #f8f8f8;
      margin: 0.3rem 0;
    }
    .radio-title {
      margin: 0.3rem 0 0.15rem 0;
    }
    textarea {
      border: 1px solid #000;
      border-radius: 0.12rem;
      width: 100%;
      height: 2rem;
      padding: 0.2rem;
    }
    .submit {
      display: block;
      width: 100%;
      height: 0.8rem;
      line-height: 0.8rem;
      margin-bottom: 0;
      margin-top: 0.2rem;
      font-size: 0.32rem;
      background: #3e3efd;
      text-align: center;
      border-radius: 0.12rem;
      color: white;
    }
    h4 {
      margin: 0.2rem 0;
      font-size: 0.32rem;
      text-align: center;
      font-weight: 500;
    }
  }
  .van-dropdown-menu {
    width: 78%;
    height: 0.6rem;
    border: none;
    border-bottom: 1px solid #000;
    padding-right: 8px;
  }
}
.question .van-radio__label {
  margin-left: 0;
}
</style>